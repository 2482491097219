import { HTMLProps } from 'react'

export enum TooltipContentTheme {
  LIGHT = 'light',
  DARK = 'dark',
}

export type ITooltipContentTheme = `${TooltipContentTheme}`

export interface ITooltipContentProps extends HTMLProps<HTMLDivElement> {
  theme?: ITooltipContentTheme
  useBoxShadow?: boolean
  usePortal?: boolean
  portalSelector?: string
  useArrow?: boolean
}
