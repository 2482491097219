import * as styleVars from '@common/styles/constants'

import React, {
  ComponentPropsWithRef,
  PropsWithChildren,
  forwardRef,
} from 'react'
import { useCombinedRefs, useOutsideClickRef } from '@common/utils/use-hooks'

import SvgChevronRight from '@static/svg/icons/arrow.svg'
import SvgSmallDropdown from '@static/svg/icons/small-dropdown.svg'
import { css } from '@emotion/react'
import dynamic from 'next/dynamic'
import styled from '@emotion/styled'
import { useRouter } from 'next/router'

const Spinner = dynamic(() => import('@common/components/spinner'), {
  ssr: false,
})

const ButtonWrapper = styled.span<ButtonProps | any>`
  cursor: pointer;

  ${(props: ButtonProps) =>
    props.btnTheme == 'copy'
      ? ''
      : css`
          display: inline-block;
          position: relative;
        `}

  ${(props: ButtonProps) => {
    if (props.selected) {
      if (props.btnTheme === 'toggle') {
        return css`
          & button {
            border-radius: 8px !important;
            box-shadow: 2px 2px 16px 0 rgba(148, 154, 171, 0.15);
            color: #3f6db4;
            background-color: #fff;
            margin-left: 0px;
            margin-right: 0px;
            z-index: 2;
          }
        `
      } else {
        return css`
          & button {
            border: solid 2px rgba(203, 208, 223, 0.61);
            color: white;
            background-color: ${styleVars.outlineBtnBorder};
          }
        `
      }
    }
    return ''
  }}

  & button {
    display: inline-flex;
    align-items: center;
    border-radius: 8px;
    padding: 12px;
    font-weight: 600;
    font-size: 14px;
    justify-content: center;

    &:disabled {
      background-color: ${styleVars.gray20};
      color: ${styleVars.gray50};
      cursor: auto;

      > svg,
      .pq-spinner {
        opacity: 0.4;
        filter: invert(77%) sepia(11%) saturate(284%) hue-rotate(187deg)
          brightness(98%) contrast(87%);
      }
    }

    & svg {
      position: relative;
      height: 1em;
      width: 1em;
      display: inline-block;
    }
  }

  .button-wrapper {
    border-radius: 8px;
    display: block;
    position: absolute;
    width: 100%;
    z-index: 1;

    ${(props) => (!props.isDropdownBtn ? 'border-radius: 8px;' : '')}
    box-shadow: 0 8px 16px 0 rgba(203, 208, 223, 0.4);
    background-color: ${styleVars.white};
  }

  .button-wrapper .pq-button {
    &:first-of-type.dropdown-button {
      & * {
        /* border-radius: 8px 8px 0 0 !important; */
        ${(props) =>
          !props.isLoading ? '' : 'border-radius: 8px 8px 0 0 !important'}
        background-clip: padding-box;
      }
    }
    &:last-of-type.dropdown-button {
      & * {
        ${(props) =>
          !props.isLoading ? '' : 'border-radius: 0 0 8px 8px !important'}
        background-clip: padding-box;
      }
    }
  }

  .pq-button.dropdown-button {
    border-radius: 0;
    width: 100%;
  }
`

export type ButtonDropdownOptions = {
  text: string
  id?: string
  onClick: (e?: any) => void
  closeAfterClick?: boolean
  isLoading?: boolean
  'data-cy'?: string
}

export type Theme =
  | 'primary-blue'
  | 'primary-red'
  | 'secondary-blue'
  | 'secondary-red'
  | 'link-primary-blue'
  | 'link-primary-red'
  | 'link-primary-warning'
  | 'toggle'
  | 'copy'
  | 'default'
  | 'default-green'
  | 'unstyled'
  | 'none'
  | 'transparent'

export type ButtonOutline =
  | 'transparent'
  | 'primary-blue'
  | 'black'
  | 'none'
  | 'primary-blue inverse'
  | 'primary-green'

const themeToColor = (theme: Theme) => {
  switch (theme) {
    case 'link-primary-warning':
      return styleVars.yellow40Accent
    case 'primary-red':
    case 'secondary-red':
    case 'link-primary-red':
      return styleVars.primaryRed
    case 'primary-blue':
    case 'secondary-blue':
    case 'link-primary-blue':
    default:
      return styleVars.primaryBlue
  }
}

export interface ButtonProps extends ComponentPropsWithRef<'button'> {
  wrapperCss?: any
  innerBtnCss?: any
  forwardedRef?: any
  // @todo @deprecate
  wrapperClassName?: string
  selected?: boolean
  isLoading?: boolean
  linkTo?: string
  btnTheme?: Theme
  outlined?: ButtonOutline
  type?: 'button' | 'submit'
  dropdownOptions?: ButtonDropdownOptions[]
  tabIndex?: number
  form?: string
}

const Button = forwardRef((props: PropsWithChildren<ButtonProps>, ref) => {
  const {
    wrapperCss = css``,
    wrapperClassName = '',
    innerBtnCss = css``,
    forwardedRef,
    children,
    id,
    linkTo,
    onClick,
    className = '',
    btnTheme = 'primary-blue',
    selected = false,
    disabled = false,
    type = 'button',
    dropdownOptions = [],
    outlined = 'none',
    tabIndex,
    form,
    isLoading,
    ...rest
  } = props
  const [showDropdown, setShowDropdown] = React.useState(false)
  const toggleDropdown = () => setShowDropdown(!showDropdown)

  const router = useRouter()

  const buttonClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) return onClick(e)
    if (linkTo) return router.push(linkTo)
    if (dropdownOptions.length) {
      e.stopPropagation()
      e.preventDefault()
      return toggleDropdown()
    }
  }

  const [outsideClickRef] = useOutsideClickRef(
    buttonClickHandler as any,
    showDropdown,
  )
  const combinedRef = useCombinedRefs(ref, forwardedRef)

  // @todo implement dropdown component
  const renderButtonDropdown = (): JSX.Element | null => {
    if (!dropdownOptions.length || !showDropdown) return null

    const buttons = dropdownOptions.map((btn, index) => {
      const borderCss = css`
        &:after {
          content: '';
          border-bottom: 1px solid ${styleVars.fadedWhite};
          width: 85%;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 100%;
        }
      `

      return (
        <Button
          id={btn?.id}
          onClick={async (...args) => {
            await btn.onClick(...args)
            if (btn?.closeAfterClick) setShowDropdown(false)
          }}
          {...rest}
          data-cy={btn['data-cy'] ?? rest['data-cy'] ?? 'button'}
          wrapperClassName="dropdown-button"
          wrapperCss={index === dropdownOptions.length - 1 ? null : borderCss}
          css={css`
            font-family: Poppins;
            font-size: 12px !important;
            font-weight: 500 !important;
            line-height: 1.54;
            color: ${styleVars.darkBlack};
            min-width: 128px;
            text-align: left !important;
            display: inline-block;
            margin: 0;
            padding: 0;
            width: 100%;
          `}
          innerBtnCss={css`
            padding-right: 0 !important;
          `}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          btnTheme="unstyled"
        >
          <span
            className=""
            css={css`
              white-space: nowrap;
              // display: inline-block;
              width: 100%;
              display: inline-flex;
              align-items: center;
              justify-content: space-between;
            `}
          >
            <span style={{ marginRight: '1em' }}>{btn.text}</span>
            {btn.isLoading ? (
              <Spinner
                className="spinner pq-mr-1"
                widthAndHeight={12}
                color={styleVars.primaryBlue}
              />
            ) : (
              <SvgChevronRight className="pq-mr-1" />
            )}
          </span>
        </Button>
      )
    })

    return (
      <span className="button-wrapper" ref={outsideClickRef}>
        {buttons}
      </span>
    )
  }

  const getThemeStyle = (): any => {
    switch (btnTheme) {
      case 'primary-blue':
        return css`
          color: ${styleVars.white};
          background-color: ${styleVars.blue50};

          &:focus:not([disabled]) {
            background-color: ${styleVars.blue40a};
          }
          &:hover:not([disabled]) {
            background-color: ${styleVars.blue40a};
          }
          &:active:not([disabled]) {
            background-color: ${styleVars.blue30};
          }
        `
      case 'primary-red':
        return css`
          color: ${styleVars.white};
          background-color: ${styleVars.primaryRed};
          &:not([disabled]) {
            box-shadow: 0 4px 12px 0 rgba(239, 39, 45, 0.3);
          }

          &:focus:not([disabled]) {
            background-color: ${styleVars.primaryRedFocused};
          }
          &:hover:not([disabled]) {
            background-color: ${styleVars.red40a};
          }

          &:active:not([disabled]) {
            background-color: ${styleVars.red30};
          }
        `
      case 'secondary-blue':
        return css`
          color: ${styleVars.primaryBlueFocused};
          background-color: ${styleVars.fadedBlue};

          &:focus:not([disabled]) {
            color: ${styleVars.blue50};
            background-color: ${styleVars.blue20};
          }
          &:hover:not([disabled]) {
            color: ${styleVars.blue50};
            background-color: ${styleVars.blue20};
          }
          &:active:not([disabled]) {
            color: ${styleVars.blue30};
            background-color: ${styleVars.blue20};
          }
        `
      case 'copy':
        return css`
          color: ${styleVars.primaryBlue};
          background-color: ${styleVars.fadedBlue};
          height: 100%;
        `

      case 'secondary-red':
        return css`
          color: ${styleVars.primaryRedFocused};
          background-color: ${styleVars.secondaryRed};

          &:hover:not([disabled]) {
            color: ${styleVars.red50};
            background-color: ${styleVars.red20};
          }

          &:active:not([disabled]) {
            color: ${styleVars.red30};
            background-color: ${styleVars.red20};
          }
        `
      case 'link-primary-blue':
        return css`
          color: ${styleVars.primaryBlue};
          background-color: transparent;
          padding: 0 !important;

          &:hover:not([disabled]) {
            opacity: 0.8;
          }
        `
      case 'link-primary-red':
        return css`
          color: ${styleVars.primaryRed};
          background-color: transparent;
          padding: 0 !important;
          &:hover:not([disabled]) {
            opacity: 0.8;
          }
        `
      case 'link-primary-warning':
        return css`
          color: ${styleVars.yellow40Accent};
          background-color: transparent;
          padding: 0 !important;
          &:hover:not([disabled]) {
            opacity: 0.8;
          }
        `
      case 'toggle':
        return css`
          color: #8a91a5;
          background-color: #f4f4f7;

          &:disabled {
            opacity: 0.9 !important;
          }
        `
      case 'default-green':
        return css`
          color: ${styleVars.darkGreen};
        `

      case 'transparent':
        return css`
          background-color: transparent;

          &:hover:not([disabled]) {
            background-color: ${styleVars.gray20};
          }
        `

      case 'none':
        return css``

      default:
        return css`
          color: ${styleVars.darkBlack};
          background-color: ${styleVars.white};

          &:focus:not([disabled]) {
            background-color: ${styleVars.fadedGrey};
          }
          &:hover:not([disabled]) {
            background-color: ${styleVars.fadedGrey};
          }
        `
    }
  }

  const getOutlinedStyle = (): any => {
    switch (outlined) {
      case 'transparent':
        return css`
          border: solid 2px transparent;
          &:hover:not([disabled]) {
            border: solid 2px transparent;
          }
        `
      case 'primary-blue':
        return css`
          border: solid 2px rgba(203, 208, 223, 0.61);
          &:hover:not([disabled]) {
            border: solid 2px ${styleVars.primaryBlue};
          }
          &:hover:not([disabled]) {
            border: solid 2px ${styleVars.primaryBlue};
          }
          &:active:not([disabled]) {
            border: solid 2px ${styleVars.blue30};
          }
        `
      case 'black':
        return css`
          border: solid 2px rgba(203, 208, 223, 0.61);
          &:hover:not([disabled]) {
            border: solid 2px ${styleVars.outlineBtnBorder};
          }
          &:focus:not([disabled]) {
            border: solid 2px ${styleVars.outlineBtnBorder};
          }
        `
      case 'primary-blue inverse':
        return css`
          border: solid 2px rgba(203, 208, 223, 0.61);
          &:hover:not([disabled]) {
            color: white;
            background-color: ${styleVars.outlineBtnBorder};
          }
          &:focus:not([disabled]) {
            border: solid 2px rgba(203, 208, 223, 0.61);
            color: white;
            background-color: ${styleVars.outlineBtnBorder};
          }
        `
      case 'primary-green':
        return css`
          border: solid 2px ${styleVars.darkGreen};
          &:hover:not([disabled]) {
            color: ${styleVars.darkGreen};
            background-color: transparent;
          }
          &:focus:not([disabled]) {
            border: solid 2px rgba(203, 208, 223, 0.61);
            color: ${styleVars.darkGreen};
            background-color: transparent;
          }
        `
      case 'none':
        return css``
      default:
        return css``
    }
  }

  const renderButtonDropdownCaret = (): JSX.Element | null => {
    if (!dropdownOptions.length) return null

    const menuActiveCss = showDropdown
      ? css`
          transform: rotate(180deg);
          & path:last-child {
            fill: ${styleVars.primaryBlue};
          }
        `
      : null
    const defaultCss = css`
      margin-left: 16px;
      margin-right: 0 !important;
    `
    const dropdownCss = [defaultCss, menuActiveCss]
    return <SvgSmallDropdown css={dropdownCss} />
  }

  const themeStyles = getThemeStyle()
  const outlineStyles = getOutlinedStyle()
  const btnCss: any = [themeStyles, outlineStyles, innerBtnCss]

  return (
    <>
      <ButtonWrapper
        selected={selected}
        className={`pq-button ${wrapperClassName}`}
        css={wrapperCss}
        isDropdownBtn={!!dropdownOptions.length}
        btnTheme={btnTheme}
      >
        <button
          tabIndex={tabIndex}
          id={id}
          data-cy={id}
          ref={combinedRef}
          css={btnCss}
          className={className}
          onClick={buttonClickHandler}
          form={form}
          // eslint-disable-next-line react/button-has-type
          type={type}
          disabled={disabled}
          {...rest}
        >
          {children}
          {isLoading && (
            <Spinner
              className="pq-ml-1 spinner"
              widthAndHeight={12}
              color={themeToColor(btnTheme)}
            />
          )}
          {renderButtonDropdownCaret()}
        </button>
        {renderButtonDropdown()}
      </ButtonWrapper>
    </>
  )
})

export default Button
