import { TooltipContent } from '@common/components/tooltip/v2/tooltip-content'
import { TooltipTrigger } from '@common/components/tooltip/v2/tooltip-trigger'
import { useFloatingUi } from '@common/hooks/use-floating-ui'
import { FloatingUiProvider } from '@common/hooks/use-floating-ui/context'
import { IFloatingUiProps } from '@common/hooks/use-floating-ui/types'
import { classNames } from '@common/utils'
import styled from '@emotion/styled'
import { PropsWithChildren } from 'react'

/**
 * @deprecated use {@palqee/ui/popover}
 */
const Tooltip = (props: PropsWithChildren<IFloatingUiProps>) => {
  const { children, className, ...options } = props

  const classes = classNames('pq-tooltip', className || '')

  const tooltip = useFloatingUi({
    ...options,
    role: 'tooltip',
  })

  return (
    <FloatingUiProvider {...tooltip}>
      <TooltipWrapper className={classes}>{children}</TooltipWrapper>
    </FloatingUiProvider>
  )
}

const TooltipWrapper = styled.span`
  &.pq-tooltip {
    position: relative;

    #arrow {
      position: absolute;
    }
  }
`

const TooltipNamespace = Object.assign(Tooltip, {
  Trigger: TooltipTrigger,
  Content: TooltipContent,
})

export { TooltipNamespace as Tooltip }
