import { cloneElement, forwardRef, HTMLProps, isValidElement } from 'react'
import { classNames } from '@common/utils'
import styled from '@emotion/styled'
import { useFloatingUiContext } from '@common/hooks/use-floating-ui/context'
import { useMergeRefs } from '@floating-ui/react'

const TooltipTrigger = forwardRef<HTMLElement, HTMLProps<HTMLElement>>(
  (props, ref) => {
    const { children, className, ...rest } = props

    const classes = classNames(
      'pq-tooltip__trigger',
      className || '',
      (children as any).props.className,
    )

    const context = useFloatingUiContext()

    const childrenRef = (children as any).ref

    const { refs, getReferenceProps, open } = context

    const mergedRef = useMergeRefs([refs.setReference, ref, childrenRef])

    const render = () => {
      if (isValidElement(children)) {
        return cloneElement(
          children,
          getReferenceProps({
            ref: mergedRef,
            className: classes,
            ...rest,
            ...children.props,
            'data-state': open ? 'open' : 'closed',
          }),
        )
      }
    }

    return <TooltipTriggerContainer>{render()}</TooltipTriggerContainer>
  },
)

const TooltipTriggerContainer = styled.div``

export { TooltipTrigger }
