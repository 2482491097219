import * as styleVars from '@common/styles/constants'

import {
  ITooltipContentProps,
  ITooltipContentTheme,
  TooltipContentTheme,
} from './types'

import { FloatingPortal, useMergeRefs } from '@floating-ui/react'
import { classNames } from '@common/utils'
import { css } from '@emotion/react'
import { forwardRef } from 'react'
import styled from '@emotion/styled'
import { useFloatingUiContext } from '@common/hooks/use-floating-ui/context'

const TooltipContent = forwardRef<HTMLDivElement, ITooltipContentProps>(
  (props, ref) => {
    const {
      children,
      className,
      theme = TooltipContentTheme.LIGHT,
      useBoxShadow = true,
      useArrow,
    } = props

    const {
      x,
      y,
      refs,
      strategy,
      getFloatingProps,
      open,
      placement,
      arrowRef,
      middlewareData,
    } = useFloatingUiContext()

    const { x: arrowX, y: arrowY } = middlewareData?.arrow ?? {}

    const mergedRef = useMergeRefs([refs.setFloating, ref])

    const getTooltipBackgroundColorClass = (
      toolTipTheme: ITooltipContentTheme,
    ) => {
      switch (toolTipTheme) {
        case TooltipContentTheme.LIGHT:
          return 'pq-background-white'
        default:
          return 'pq-background-dark-blue'
      }
    }

    const getTooltipTextColorClass = (toolTipTheme: ITooltipContentTheme) => {
      switch (toolTipTheme) {
        case TooltipContentTheme.LIGHT:
          return 'pq-color-dark-black'
        default:
          return 'pq-color-faded-white'
      }
    }

    const tooltipBackgroundColorClass = getTooltipBackgroundColorClass(theme)
    const tooltipTextColorClass = getTooltipTextColorClass(theme)
    const boxShadowClass = useBoxShadow ? 'pq-box-shadow' : ''

    const classes = classNames(
      'pq-tooltip__content',
      className || '',
      tooltipBackgroundColorClass,
      tooltipTextColorClass,
      boxShadowClass,
    )

    const staticSide = {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right',
    }[placement.split('-')[0]]

    const { style, ...floatingProps } = getFloatingProps(props)

    return (
      open && (
        <FloatingPortal>
          <TooltipContentContainer
            className={classes}
            ref={mergedRef}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              zIndex: 9999,
              maxWidth: '500px',
              ...style,
            }}
            {...floatingProps}
          >
            {children}

            {useArrow && (
              <TooltipContentArrowNub
                id="pq-tooltip__content-arrow"
                ref={arrowRef}
                theme={theme}
                style={{
                  position: 'absolute',
                  width: '10px',
                  height: '10px',
                  background: '#fff',
                  left: arrowX !== null ? `${arrowX}px` : '',
                  top: arrowY !== null ? `${arrowY}px` : '',
                  [staticSide]: '-5px',
                  transform: 'rotate(45deg)',
                }}
              />
            )}
          </TooltipContentContainer>
        </FloatingPortal>
      )
    )
  },
)

const TooltipContentArrowNub = styled.div<{
  theme?: ITooltipContentTheme
}>`
  ${({ theme }) =>
    theme === TooltipContentTheme.LIGHT
      ? css`
          background-color: ${styleVars.white};
        `
      : css`
          background-color: ${styleVars.outlineBtnBorder} !important;
        `}
`

const TooltipContentContainer = styled.div`
  padding: 1em;
  border-radius: 0.5em;
  white-space: pre-line;
  word-break: break-all;

  font-size: 12px;
  font-weight: 500;
`

export { TooltipContent }
