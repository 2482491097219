import { useCallback, useMemo, useRef, useState } from 'react'
import {
  useFloating,
  autoUpdate,
  useDismiss,
  useHover,
  safePolygon,
  useClick,
  useRole,
  useInteractions,
  useClientPoint,
} from '@floating-ui/react'
import { arrow } from '@floating-ui/dom'
import { IFloatingUiProps, IUseFloatingReturn } from './types'

export const useFloatingUi = (props: IFloatingUiProps): IUseFloatingReturn => {
  const {
    placement = 'left-end',
    strategy = 'absolute',
    enableHover = false,
    enableClick = true,
    defaultIsOpen = false,
    enableDismiss = true,
    enableAncestorScrollDismiss = false,
    enableClientPoint = false,
    middleware,
    role = 'menu',
    hoverProps,
    useArrow = false,
    useClientPointProps,
    open: controlledOpen,
    onOpenChange: controlledOnOpenChange,
    ...options
  } = props

  const [labelId, setLabelId] = useState<string | undefined>()
  const [descriptionId, setDescriptionId] = useState<string | undefined>()
  const [uncontrolledOpen, uncontrolledOnOpenChange] = useState(defaultIsOpen)

  const open = controlledOpen ?? uncontrolledOpen
  const setOpen = controlledOnOpenChange ?? uncontrolledOnOpenChange

  const arrowRef = useRef<HTMLDivElement>(null)

  const buildMiddleware = useCallback(() => {
    if (middleware ?? useArrow) {
      return [
        ...middleware,
        arrow({
          element: arrowRef.current,
        }),
      ]
    }

    return []
  }, [middleware, useArrow])

  const floatingData = useFloating({
    open,
    onOpenChange: setOpen,
    placement,
    strategy,
    whileElementsMounted: autoUpdate,
    middleware: buildMiddleware(),
    ...options,
  })

  const context = floatingData.context as any

  const dismiss = useDismiss(context, {
    enabled: enableDismiss,
    outsidePressEvent: 'mousedown',
    ancestorScroll: enableAncestorScrollDismiss,
  })

  const hover = useHover(context, {
    enabled: enableHover,
    delay: {
      open: 0,
      close: 300,
    },
    move: false,
    handleClose: safePolygon(),
    ...hoverProps,
  })

  const click = useClick(context, {
    enabled: enableClick,
    ignoreMouse: enableHover,
  })

  const roleUi = useRole(context, { role })

  const clientPoint = useClientPoint(context, {
    enabled: enableClientPoint,
    ...useClientPointProps,
  })

  const interactions = useInteractions([
    click,
    hover,
    dismiss,
    roleUi,
    clientPoint,
  ])

  return useMemo<IUseFloatingReturn>(
    () => ({
      open,
      setOpen,
      arrowRef,
      ...interactions,
      ...floatingData,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
    }),
    [
      open,
      setOpen,
      interactions,
      floatingData,
      labelId,
      descriptionId,
      arrowRef,
    ],
  )
}
